import { useEffect, useState } from 'react';
import EncryptedStorage from 'react-native-encrypted-storage';
import useSetToken from './useSetToken';

const useToken = () => {
   const [token, setToken] = useState<string | null>(null)
   const [loading, setLoading] = useState(true)

   const [listener, _] = useSetToken()

   useEffect(() => {
      EncryptedStorage.getItem("token").then((token) => {
         setToken(token)
         
      }).finally(() => {
         setLoading(false)
      })
   }, [setLoading, setToken, listener])
 
  
   return [token, loading]
}

export default useToken