import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Colors, Column, Gap, Row, Spacer } from "layout";
import Menu from 'components/menu';
import { ScrollView, useWindowDimensions, View } from 'react-native';
import { ErrorBoundary } from 'ErrorBoundary';
import AccountContext from 'accountcontext';
import { Button, Icon, Overlay, Text, useTheme } from 'react-native-elements';
import useNavigate from 'hooks/useNavigate';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import { useLocation } from 'routing';
import BusinessContext from 'businesscontext';
import Welcome from 'components/welcome';
import useSetToken from 'hooks/useSetToken';

const AccountControls = () => {
    const account = useContext(AccountContext)
    const authenticated = useIsAuthenticated()
    const navigate = useNavigate()

    const [open, setOpen] = useState(false)

    const [_, setToken] = useSetToken()

    const onLogout = useCallback(() => {
        if(confirm("Log out?")) {
            setToken()
            navigate("/")
        }
    }, [setToken, navigate])
    
    const menuRef = useRef(null)

    useEffect(() => {
        const closeOpenMenus = (e) => {
            if(menuRef.current && open && !menuRef.current.contains(e.target)){
              setOpen(false)
            }
        }

        document.addEventListener('mousedown', closeOpenMenus)
        return () => document.removeEventListener('mousedown', closeOpenMenus)
    }, [menuRef, setOpen, open])
    

    return <>{ authenticated === "YES" && <Row style={{justifyContent: 'flex-end', flexGrow: 0, flexShrink: 0, flexBasis: 50, zIndex: 10}}>
        <View ref={menuRef} onStartShouldSetResponder={() => setOpen(!open)} style={{borderColor: "#eee", boxShadow: open ? "rgba(149, 157, 165, 0.2) 0px 8px 24px" : "none", backgroundColor: open ? "#fff" : "inherit", borderWidth: open ? 1 : 0, flexDirection: "row", padding: open ? 9 : 10, cursor: open ? "default": "pointer"}}>
            <Column><Row><Icon name="account-circle" />
            <Text> {account?.email}</Text></Row>
            { open && <Button title="Log out" onPress={onLogout}></Button>}
            </Column>
        </View>
       
    </Row>}</>
}

const Wrapper = ({children}) => {
    const business = useContext(BusinessContext)
    const navigate = useNavigate()
    const authenticated = useIsAuthenticated()
    const location = useLocation()

    const d = useWindowDimensions()

    useEffect(() => {
      if (authenticated === "NO" && location.pathname != "/authenticate") {
        navigate("/authenticate")
      }
    }, [authenticated])


    const [mobile, setMobile] = useState(d.width <= 500)
    
    useEffect(() => {
        setMobile(d.width <= 500)
    }, [setMobile, d])

    if(authenticated === "UNRESOLVED" || (authenticated === "NO" && location.pathname != "/authenticate")) {
        return <></>
    }

    if(authenticated === "YES" && !business && location.pathname !== "/") {
        return <></>
    }
return <View style={{minHeight: "100vh", minWidth: "100vw", justifyContent: 'stretch', flexDirection: mobile ? "column" : "row"}}>
        <Menu mobile={mobile} />
        <Column style={{
            flexBasis: 200,
            flexGrow: 1,
            flexShrink: 0,
            backgroundColor: "#fff",
            padding: 0,
            height: "100vh",
        }}>
            
            <ScrollView style={{padding: location.pathname != "/authenticate" ? 20 : 0, paddingBottom: 0}} contentContainerStyle={{height: '100%'}}>
                <AccountControls></AccountControls>
                <ErrorBoundary>
                    { children }
                </ErrorBoundary>
                <Text style={{textAlign: 'center', fontSize: 10, color: "#bbb", zIndex: 10, flexGrow: 0, flexBasis: 30, marginTop: 'auto', padding: 20}}>&copy; 2023 Measurably Green by Adless ApS</Text>
            </ScrollView>
        </Column>
    </View>
}

export default Wrapper;