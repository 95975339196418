import { StyleSheet } from 'react-native';
import { Theme as ThemeT } from 'react-native-elements';
import { createGlobalStyle } from 'styled-components';

export const Theme: ThemeT = {
    colors: {
      primary: "#000",
    },
    Text: {
      style: {
        justifyContent: "inherit",
        fontSize: 15,
        fontFamily: 'inherit',
        marginBottom: 5,
        lineHeight: '2em',
        fontWeight: 100,
        
      },
      h1Style: {
        fontSize: 42,
        fontWeight: "normal",
        marginTop: 15,
        letterSpacing: '0.2ch'
      },
      h2Style: {
        marginTop: 10,
        fontSize: 25,
        letterSpacing: '0.2ch'
      },
      h3Style: {
        marginTop: 10,
        fontSize: 20,
        letterSpacing: '0.2ch'
      },
      h4Style: {
        marginTop: 10,
        fontSize: 14,
        fontWeight: 'bold',
        letterSpacing: '0.2ch'
      }
    },
  
    Button: {
      buttonStyle: {
        marginTop: 20,
      },
      titleStyle: {
        fontFamily: 'inherit'
      },
      containerStyle: {
        flexShrink: 1,
      }
    },

    ButtonGroup: {
      containerStyle: {
        marginHorizontal: 0,
        marginVertical: 0
      },
      textStyle: {
        fontFamily: 'inherit',
        margin: 0,
      }
    },

    Card: {
      containerStyle: {
        borderRadius: 10,
        border: 0,
        boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
      },
      titleStyle: {
      },
    },

    CardDivider: {
      style: {
        borderBottomColor: "#ddd",
        marginVertical: 20,
        marginBottom: 0,
        marginHorizontal: -15,
      }
    },

    FAB: {
        buttonStyle: {
            marginTop: 0,
        },
        iconContainerStyle: {
            padding: 0,
            height: 50,
            width: 50,
            lineHeight: 50,
        }
    },
  
    colors: {
      primary: "#000",
      secondary: "#fff"
    },
  
    Input: {
      containerStyle: {
        paddingHorizontal: 0,
        flex: 1,
      },
      style: {
        fontFamily: 'inherit',
        fontSize: 'inherit'
      },
      inputContainerStyle: {
        borderWidth: 0,
        borderBottomWidth: 1,
        borderColor: "rgb(134, 147, 158)",
        padding: 0,
        justifyContent: "inherit",
        lineHeight: '1.5em',
        appearance: "none",
        WebkitAppearance: "none",
        MozAppearance: "none",
        borderRadius: 0,
        background: "none",
        color: "inherit",
        height: 30,
      }
    }
  };
  
  export const GlobalStyles = createGlobalStyle`
    * {
      font-family: sans-serif;
      letter-spacing: 0.1ch;
      text-underline-offset: 0.5ch;
    }`