import jwtDecode from "jwt-decode"
import useSetToken from "./useSetToken"
import useToken from "./useToken"

const useIsAuthenticated = () => {
    const [token, loading] = useToken()
    const [_, setToken] = useSetToken()
    if (loading) {
        return "UNRESOLVED"
    }

    if (!!!token) {
        return "NO"
    }

    let data;
    try {
        data = <any>jwtDecode(token)
    } catch {
        setToken()
        return "UNRESOLVED"
    }

    return !!data["sub"] ? "YES" : "NO"
}

export default useIsAuthenticated