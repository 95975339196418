import AccountContext from 'accountcontext'
import BusinessContext from 'businesscontext'
import moment from 'moment'
import React, { useCallback, useContext, useState } from 'react'
import { API } from 'api'
import { Business, integrations, Users, datahub, proxy, Records } from 'api/gen/api.pb';
import { ProxyRequest, ProxyResponse } from 'api/gen/api'
import { InitReq } from 'api/gen/fetch.pb'
import useIsAuthenticated from './useIsAuthenticated'
import useToast from './useToast'

function loadedData<T>(data: T): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        resolve(data)
    })
}

const useAPI = (): API => {
    const account = useContext(AccountContext)
    const business = useContext(BusinessContext)
    const toast = useToast()

    const withBusinessID = useCallback(c => {
        for (const key of Object.getOwnPropertyNames(c)) {
            if (typeof c[key] === 'function') {
                const oldFunc = c[key]
                c[key] = (req, initReq) => oldFunc({ businessId: business?.id, ...req }, initReq)
            }
        }

        return c
    }, [])

    const withAccountID = useCallback(c => {
        for (const key of Object.getOwnPropertyNames(c)) {
            if (typeof c[key] === 'function') {
                const oldFunc = c[key]
                c[key] = (req, initReq) => oldFunc({ userId: account?.id, ...req }, initReq)
            }
        }

        return c
    }, [])

    const withErrorHandler = useCallback(c => {
        for (const key of Object.getOwnPropertyNames(c)) {
            if (typeof c[key] === 'function') {
                const oldFunc = c[key]
                c[key] = (req, initReq) => oldFunc(req, initReq).catch(res => {
                    if (initReq?.mute) {
                        throw res
                    }

                    if(res.bodyUsed) {
                        throw res
                    }

                    if(!res.json) {
                        toast.show({
                            type: "error",
                            text1: "Please reload page",
                            text2: res?.message,
                            autoHide: false,
                        })

                        throw res
                    }

                    return res.json().then(body => body?.details?.at(0)?.cause).then(message => {
                        toast.show({
                            type: "error",
                            text1: res?.statusText ?? "Error",
                            text2: message ?? undefined
                        })

                        throw res
                    }, () => {
                        toast.show({
                            type: "error",
                            text1: res?.statusText ?? "Error",
                        })
                    }).finally(() => {
                        throw res
                    })
                })
            }
        }

            return c
        }, [])

    const [api, _] = useState({
        user: withErrorHandler(withAccountID(Users)),
        business: withErrorHandler(withBusinessID(Business)),
        proxy: withErrorHandler(proxy),
        datahub: withErrorHandler(withBusinessID(datahub)),
        external: withErrorHandler(withBusinessID(integrations)),
        records: withErrorHandler(withBusinessID(Records)),
    })

    return api
}

export default useAPI