import { createBrowserHistory } from 'history';
import React from 'react'
import { Platform } from 'react-native';

export let Router: any
export let Route: any
export let Routes: any
export let useLocation: any
if (Platform.OS === 'web') {
  let r = require('react-router-dom')
  Router = r.BrowserRouter
  Route = r.Route
  Routes = r.Routes
  useLocation = r.useLocation
} else {
  let r = require('react-router-native')
  Router = r.NativeRouter
  Route = r.Route
  Routes = r.Switch
  useLocation = r.useLocation
}

export const history = createBrowserHistory();