import BusinessContext from 'businesscontext';
import useNavigate from 'hooks/useNavigate';
import { Column, Row, Spacer } from 'layout';
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Icon, Text } from 'react-native-elements';

const MenuItem = ({ icon, label, target, expanded, onPress }) => {
    const navigate = useNavigate()
    return <Row style={{
        alignItems: "center",
        flex: "0 0 50",
    }} onPress={() => {
        onPress && onPress()
        navigate(target)
    }}>
        <Icon color="#fff" name={icon} onPress={() => {
            onPress && onPress()
            navigate(target)
        }} />
        <Text style={{
            padding: 10,
            color: "#fff",
            marginBottom: 0,
        }} onPress={() => {
            onPress && onPress()
            navigate(target)
        }}>{expanded ? label : ''}</Text></Row>
}

const Menu = ({ mobile }) => {
    const [isMenuOpen, setIsMenuOpen] = useState(!localStorage.getItem("menuClosed"))
    const business = useContext(BusinessContext)

    useEffect(() => {
        if (mobile) localStorage.setItem("menuClosed", "true")
    }, [mobile])

    useEffect(() => {
        isMenuOpen ? localStorage.removeItem("menuClosed") : localStorage.setItem("menuClosed", "true")
    }, [isMenuOpen])

    const onNavigate = useCallback(() => {
        if (mobile) localStorage.setItem("menuClosed", "true")
    }, [mobile])

    if (!!!business) {
        return <></>
    }

    return <Column style={{
        flexBasis: (mobile ? (isMenuOpen ? "auto" : 60) : (isMenuOpen ? 300 : 60)),
        flexGrow: 0,
        flexShrink: 0,
        flexWrap: 'nowrap',
        backgroundColor: "#000",
        paddingVertical: mobile ? 5 : 20,
        paddingHorizontal: mobile ? 10 : 20,
    }}>
        <Row style={{ flexBasis: 50, flexGrow: 0, flexWrap: 'nowrap' }}>
            <Text style={{ height: 50 }} onPress={() => setIsMenuOpen(!isMenuOpen)}><Icon containerStyle={{ marginVertical: 10 }} color="#fff" name='menu'></Icon></Text>
            {(isMenuOpen || mobile) && <Text h4 style={{ color: "#FFF", paddingLeft: 10 }}>{business?.name}</Text>}
        </Row>

        {(!mobile || isMenuOpen) && <>
            <Spacer />
            <MenuItem onPress={onNavigate} expanded={isMenuOpen} icon="home" label="Overview" target="/"></MenuItem>
            <MenuItem onPress={onNavigate} expanded={isMenuOpen} icon="description" label="Reports" target="/report"></MenuItem>
            <MenuItem onPress={onNavigate} expanded={isMenuOpen} icon="format-list-numbered" label="Accounting" target="/accounting"></MenuItem>
            <MenuItem onPress={onNavigate} expanded={isMenuOpen} icon="sync" label="Datahub" target="/datahub"></MenuItem>
            <MenuItem onPress={onNavigate} expanded={isMenuOpen} icon="verified" label="Certifications" target="/certifications"></MenuItem>
            <Spacer />
            <MenuItem onPress={onNavigate} expanded={isMenuOpen} icon="settings" label="Settings" target="/settings"></MenuItem>
        </>}
    </Column>
}

export default Menu;