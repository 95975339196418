import { useEffect, useState } from 'react';
import EncryptedStorage from 'react-native-encrypted-storage';

// Manual implementation of observer pattern to force refresh on everything that depends on useToken when token is updated

let updateCounter = 0;
let observers: React.Dispatch<React.SetStateAction<number>>[] = [];

export const setGlobalToken = (token: string = "") => {
  EncryptedStorage.setItem("token", token).then(() => {
    updateCounter++
    observers && observers.forEach((update) => update(updateCounter))
  })
};

export const useSetToken = (): [number, (token?: string) => void] => {
  const [updateCounterState, setGlobalTokenState] = useState<number>(updateCounter);

  useEffect(() => {
    observers.push(setGlobalTokenState);

    return () => {
      observers = observers.filter((update) => update !== setGlobalTokenState);
    };
  }, []);

  return [updateCounterState, setGlobalToken];
};

export default useSetToken