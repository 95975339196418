import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Modal as RNModal, ViewStyle } from 'react-native';

import { Column, Row } from 'layout';
import { Button, Card, CheckBox, ThemeContext } from 'react-native-elements';
import { useLocation } from 'react-router-dom';

type Props = {
    children?: any,
    style?: ViewStyle,
}

export const Modal = (props: Props) => {
    const { theme } = useContext(ThemeContext)

    return (
        <RNModal>
           <props.children></props.children> 
        </RNModal>
    );
};

type ModalPops = {
    onClose?: () => void
}

type ConfirmProps = {
    style?: ViewStyle
    onConfirm: () => void
    onClose?: () => void
    tos?: React.ReactElement
}

let showModal: (modal: React.ReactElement) => void;
let closeModal: () => void;

export const ModalContainer = () => {
    const [stack, setStack] = useState(Array<React.ReactElement>())

    useEffect(() => {
        showModal = (modal: React.ReactElement) => {
            stack.push(modal)
            setStack([...stack])
        }
        closeModal = () => {
            stack.pop()
            setStack([...stack])
        }
    }, [stack, setStack])

    return <>
        {stack?.at(-1)}
    </>
}

type modalFactory = (close: () => void) => React.ReactElement  

export const useModal = (modal: modalFactory) => {
    const onClose = useCallback((resolve) => {
        return () => {
            resolve()
            closeModal()
        }
    }, [closeModal])

    const show = useCallback(async () => {
        let r
        const promise = new Promise((resolve) => {
            r = resolve
        })
        const e = (
        <RNModal transparent={true}>
            <Row style={{height: "100vh", backgroundColor: "rgba(0,0,0,0.8)", alignItems: 'flex-start', justifyContent: 'center'}}>
                <Card containerStyle={{maxHeight: "80vh", maxWidth: "80vw", overflow: 'scroll', minWidth: "50vw", marginTop: "5vh"}}>
                <div style={{padding: 10}}>
                {modal(onClose(r))}
            </div>
            <Row style={{justifyContent: 'center'}}>
                <Button onPress={onClose(r)} title="Close" />
            </Row>
            </Card>
            </Row>
        </RNModal>)

        showModal(e)

        return promise
    }, [showModal, modal, onClose])

    return show
}