import React from 'react'
import { PropsWithChildren, useEffect, useState } from "react";
import { StyleSheet, useColorScheme, View, ViewStyle } from "react-native";

export const Colors = {
  primary: "#DB9D00",
  error: "#ED4337"
}

const styles = StyleSheet.create({
    sectionContainer: {
      marginTop: 32,
      marginBottom: 32,
      backgroundColor: "inherit",
      width: "100%",
    },
    highlight: {
      fontWeight: '700',
    },
  });

export const Section: React.FC<
  PropsWithChildren<{
    style?: ViewStyle;
    containerStyle?: ViewStyle;
  }>
> = ({ children, style, containerStyle }) => {
  const isDarkMode = useColorScheme() === 'dark';
  return (
    <View style={[styles.sectionContainer, containerStyle]}>
        {children}
    </View>
  );
};

export const Spacer = ({size = 30, large = false}) => {
  return <View style={{height: large ? 100 : size}}></View>
}

export const Gap = ({size = 10}) => {
  return <View style={{width: size, height: size}}></View>
}

export const Row = (props) => {
  return <View {...props} style={{width: "100%", flexDirection: "row", justifyContent: props.center ? "center":"flex-start", alignItems: "stretch", flexWrap: "wrap", flexShrink: 0, gap: props.gap, ...props.style}}>{props.children}</View>
}

export const Column = (props) => {
  return <View {...props} style={{width: "100%", flexDirection: "column", justifyContent: props.center ? "center":"flex-start", alignItems: "stretch", alignContent: "stretch", flexWrap: "wrap", ...props.style}}>{props.children}</View>
}

export const EmailLink = ({ style, to }: Props) => {
    return (<a href={"mailto:" + to} target="_blank" style={{color: "inherit", ...style}}>{to}</a>)
}

type SupportEmailLinkProps = {
    style?: TextStyle,
}

export const SupportEmailLink = ({ style }: SupportEmailLinkProps) => {
    return <EmailLink style={style} to="contact@measurably.green"></EmailLink>
}
