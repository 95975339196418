import { ErrorBoundary } from './ErrorBoundary';
import React, { createContext, useEffect, useState } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import {
  Platform, StatusBar,
  View
} from 'react-native';
import { ThemeProvider } from 'react-native-elements';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import Wrapper from 'wrapper';
import views from 'routes'
import NotFound from 'not-found';
import { history, Route, Router, Routes } from 'routing';
import { ModalContainer } from 'components/modal';
import useAPI from 'hooks/useAPI';
import BusinessContext, { GlobalBusiness } from 'businesscontext';
import AccountContext from 'accountcontext';
import { GlobalStyles, Theme } from 'theme';
import useUserID from 'hooks/useUserID';
import useIsAuthenticated from 'hooks/useIsAuthenticated';
import useSetToken from 'hooks/useSetToken';
import useToast from 'hooks/useToast';

Object.defineProperty(String.prototype, 'UCFirst', {
  value: function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

Object.defineProperty(String.prototype, 'TitleCase', {
  value: function () {
    var sentence = this.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    return sentence.join(" ")
  },
  enumerable: false
});

const App = () => {
  const Toast = useToast()

  useEffect(() => {
    const scrollToHashElement = (hash: string) => {
      setTimeout(() => {
        const elementToScroll = document.getElementById(hash?.replace("#", ""));

        if (!elementToScroll) return;

        window.scrollTo({
          left: 0,
          top: elementToScroll.offsetTop,
          behavior: "smooth"
        });
      }, 100);
    };

    scrollToHashElement(window.location.hash);

    window.history.pushState = new Proxy(window.history.pushState, {
      apply: (target, thisArg, argArray) => {
        let ret = target.apply(thisArg, argArray);

        let hash = ""
        if (argArray.length >= 3) {
          const split = argArray[2].split("#")
          if (split.length == 2) {
            hash = split[1]
          }
        }
        scrollToHashElement(hash);

        return ret
      },
    });
  }, [window]);

  const authenticated = useIsAuthenticated()
  const [_, setToken] = useSetToken()

  const api = useAPI()
  const [business, setBusiness] = useState()
  const [account, setAccount] = useState()
  useEffect(() => {
    if(authenticated === "YES") {
      const ur = api.user.Read({}).then(({user}) => {
        setAccount(user)
        if(!(user?.businesses?.length > 0)){
          throw null
        }
        return user.businesses[0]
      })

      ur.then(id => api.business.Get({businessId: id})).then(res => setBusiness(res.business))
    }
  }, [authenticated, setToken])

  useEffect(() => {
    console.log("[BUSINESS]", business)
  }, [business])


  return (
    <ThemeProvider theme={Theme}>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <SafeAreaProvider>
          <GlobalStyles />
          <Router history={history}>
            <View style={{ display: "flex", minHeight: "100vh" }}>
              <StatusBar />
              <View style={{ flexGrow: 1 }}>
                <ErrorBoundary>
                  <AccountContext.Provider value={account}>
                  <BusinessContext.Provider value={business}>
                    <ModalContainer></ModalContainer>
                    <Wrapper>
                      <Routes>
                        {lazyRoute("/", views.home)}
                        {lazyRoute("/report", views.report)}
                        {lazyRoute("/accounting", views.accounting)}
                        {lazyRoute("/datahub", views.datahub)}
                        {lazyRoute("/datahub/source/connect", views.datahubConnectSource)}
                        {lazyRoute("/datahub/source/connect/:type", views.datahubConnectSource)}
                        {lazyRoute("/datahub/source/sync", views.datahubSyncSource)}
                        {lazyRoute("/certifications", views.certifications)}
                        {lazyRoute("/certifications/issue", views.certificationsIssue)}
                        {lazyRoute("/settings", views.settings)}
                        {lazyRoute("/authenticate", views.authenticate)}
                        <Route path="*" element={<NotFound></NotFound>}></Route>
                      </Routes>
                    </Wrapper>
                  </BusinessContext.Provider>
                  </AccountContext.Provider>
                </ErrorBoundary>
              </View>
            </View>
          </Router>
        </SafeAreaProvider>
      </SkeletonTheme>
      <Toast />
    </ThemeProvider>
  );
};

function lazyRoute(path: string, C: React.LazyExoticComponent<() => JSX.Element>): any {
  return <Route exact path={path} element={<><React.Suspense fallback={<View></View>}><C /></React.Suspense></>} />
}

export default App;
