import { Column, Spacer } from 'layout'
import React from 'react'
import { Text } from 'react-native-elements';

const NotFound = () => {
    return <Column center>
        <Spacer large />
        <Text h1 style={{textAlign: 'center'}}>Not Found</Text>
        <Spacer />
        <Text style={{textAlign: 'center'}}>Something does not look right here 🤔</Text>
    </Column>
}

export default NotFound;