/* eslint-disable */
// @ts-nocheck
/*
* This file is a generated Typescript file for GRPC Gateway, DO NOT MODIFY
*/

import * as CommonCommon from "./common.pb"
import * as EntitiesEntities from "./entities.pb"
import * as fm from "./fetch.pb"

export enum SignInMethod {
  UNKNOWN = "UNKNOWN",
  APPLE = "APPLE",
  GOOGLE = "GOOGLE",
  TOKEN = "TOKEN",
  PASSWORD = "PASSWORD",
  TEST = "TEST",
}

export type Empty = {
}

export type UserCreateRequest = {
  email?: string
  password?: string
}

export type UserCreateResponse = {
  userId?: string
  token?: string
}

export type UserReadRequest = {
  userId?: string
}

export type UserReadResponse = {
  user?: EntitiesEntities.User
}

export type UserSignInRequest = {
  method?: SignInMethod
  token?: string
}

export type UserSignInResponse = {
  userId?: string
  token?: string
}

export type UserDeleteRequest = {
  userId?: string
  email?: string
}

export type UserDeleteResponse = {
}

export type BusinessGetRequest = {
  businessId?: string
}

export type BusinessGetResponse = {
  business?: EntitiesEntities.Business
}

export type BusinessCreateRequest = {
  business?: EntitiesEntities.Business
}

export type BusinessCreateResponse = {
  businessId?: string
}

export type BusinessUpdateRequest = {
  businessId?: string
  business?: EntitiesEntities.Business
}

export type BusinessUpdateResponse = {
}

export type Datahub_GetSourceStateRequest = {
  businessId?: string
  id?: string
}

export type Datahub_GetSourceStateResponse = {
  state?: EntitiesEntities.DataSource
}

export type Datahub_SetSourceStateRequest = {
  businessId?: string
  state?: EntitiesEntities.DataSource
}

export type Datahub_SetSourceStateResponse = {
}

export type Datahub_ListSourcesRequest = {
  businessId?: string
}

export type Datahub_ListSourcesResponse = {
  states?: EntitiesEntities.DataSource[]
}

export type Datahub_DeleteSourceRequest = {
  businessId?: string
  id?: string
}

export type Datahub_DeleteSourceResponse = {
}

export type Datahub_RecordsRequest = {
  businessId?: string
}

export type Datahub_RecordsResponse = {
  records?: EntitiesEntities.Record[]
}

export type Energinet_GetReadingsRequest = {
  businessId?: string
  start?: string
}

export type Energinet_GetReadingsResponseReading = {
  t?: string
  c?: number
  u?: number
}

export type Energinet_GetReadingsResponseResult = {
  readings?: Energinet_GetReadingsResponseReading[]
  id?: string
  address?: CommonCommon.Address
}

export type Energinet_GetReadingsResponse = {
  results?: {[key: string]: Energinet_GetReadingsResponseResult}
  start?: string
  end?: string
}

export type ProxyRequest = {
  url?: string
  method?: string
  body?: string
  headers?: {[key: string]: string}
}

export type ProxyResponse = {
}

export type Records_CreateRequest = {
  businessId?: string
  record?: EntitiesEntities.Record
}

export type Records_CreateResponse = {
}

export type Records_QueryRequestFilter = {
  includeCategories?: EntitiesEntities.Category[]
  includeProductionUnitIds?: string[]
  start?: string
  end?: string
  excludeEstimates?: boolean
}

export type Records_QueryRequest = {
  businessId?: string
  filter?: Records_QueryRequestFilter
}

export type Records_QueryResponse = {
  records?: EntitiesEntities.Record[]
}

export class Users {
  static Read(req: UserReadRequest, initReq?: fm.InitReq): Promise<UserReadResponse> {
    return fm.fetchReq<UserReadRequest, UserReadResponse>(`/v1/user/${req["userId"]}?${fm.renderURLSearchParams(req, ["userId"])}`, {...initReq, method: "GET"})
  }
  static SignIn(req: UserSignInRequest, initReq?: fm.InitReq): Promise<UserSignInResponse> {
    return fm.fetchReq<UserSignInRequest, UserSignInResponse>(`/v1/user`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static Create(req: UserCreateRequest, initReq?: fm.InitReq): Promise<UserCreateResponse> {
    return fm.fetchReq<UserCreateRequest, UserCreateResponse>(`/v1/user/create`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static Delete(req: UserDeleteRequest, initReq?: fm.InitReq): Promise<UserDeleteResponse> {
    return fm.fetchReq<UserDeleteRequest, UserDeleteResponse>(`/v1/user/${req["userId"]}/delete`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}
export class Business {
  static Get(req: BusinessGetRequest, initReq?: fm.InitReq): Promise<BusinessGetResponse> {
    return fm.fetchReq<BusinessGetRequest, BusinessGetResponse>(`/v1/business/${req["businessId"]}?${fm.renderURLSearchParams(req, ["businessId"])}`, {...initReq, method: "GET"})
  }
  static Create(req: BusinessCreateRequest, initReq?: fm.InitReq): Promise<BusinessCreateResponse> {
    return fm.fetchReq<BusinessCreateRequest, BusinessCreateResponse>(`/v1/business`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static Update(req: BusinessUpdateRequest, initReq?: fm.InitReq): Promise<BusinessUpdateResponse> {
    return fm.fetchReq<BusinessUpdateRequest, BusinessUpdateResponse>(`/v1/business/${req["businessId"]}`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}
export class datahub {
  static GetSourceState(req: Datahub_GetSourceStateRequest, initReq?: fm.InitReq): Promise<Datahub_GetSourceStateResponse> {
    return fm.fetchReq<Datahub_GetSourceStateRequest, Datahub_GetSourceStateResponse>(`/v1/business/${req["businessId"]}/datahub/source/state/${req["id"]}?${fm.renderURLSearchParams(req, ["businessId", "id"])}`, {...initReq, method: "GET"})
  }
  static SetSourceState(req: Datahub_SetSourceStateRequest, initReq?: fm.InitReq): Promise<Datahub_SetSourceStateResponse> {
    return fm.fetchReq<Datahub_SetSourceStateRequest, Datahub_SetSourceStateResponse>(`/v1/business/${req["businessId"]}/datahub/source/state`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static ListSources(req: Datahub_ListSourcesRequest, initReq?: fm.InitReq): Promise<Datahub_ListSourcesResponse> {
    return fm.fetchReq<Datahub_ListSourcesRequest, Datahub_ListSourcesResponse>(`/v1/business/${req["businessId"]}/datahub/source?${fm.renderURLSearchParams(req, ["businessId"])}`, {...initReq, method: "GET"})
  }
  static Records(req: Datahub_RecordsRequest, initReq?: fm.InitReq): Promise<Datahub_RecordsResponse> {
    return fm.fetchReq<Datahub_RecordsRequest, Datahub_RecordsResponse>(`/v1/business/${req["businessId"]}/datahub/records?${fm.renderURLSearchParams(req, ["businessId"])}`, {...initReq, method: "GET"})
  }
  static DeleteSource(req: Datahub_DeleteSourceRequest, initReq?: fm.InitReq): Promise<Datahub_DeleteSourceResponse> {
    return fm.fetchReq<Datahub_DeleteSourceRequest, Datahub_DeleteSourceResponse>(`/v1/business/${req["businessId"]}/datahub/source/state/${req["id"]}`, {...initReq, method: "DELETE"})
  }
}
export class integrations {
  static Energinet_GetReadings(req: Energinet_GetReadingsRequest, initReq?: fm.InitReq): Promise<Energinet_GetReadingsResponse> {
    return fm.fetchReq<Energinet_GetReadingsRequest, Energinet_GetReadingsResponse>(`/v1/integration/energinet/readings`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}
export class proxy {
  static Proxy(req: ProxyRequest, initReq?: fm.InitReq): Promise<ProxyResponse> {
    return fm.fetchReq<ProxyRequest, ProxyResponse>(`/v1/proxy`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}
export class Records {
  static Create(req: Records_CreateRequest, initReq?: fm.InitReq): Promise<Records_CreateResponse> {
    return fm.fetchReq<Records_CreateRequest, Records_CreateResponse>(`/v1/business/${req["businessId"]}/record`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
  static Query(req: Records_QueryRequest, initReq?: fm.InitReq): Promise<Records_QueryResponse> {
    return fm.fetchReq<Records_QueryRequest, Records_QueryResponse>(`/v1/business/${req["businessId"]}/record/query`, {...initReq, method: "POST", body: JSON.stringify(req, fm.replacer)})
  }
}