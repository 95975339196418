import { useCallback } from 'react';
import { history } from '../routing';

export default function useNavigate(replace?: boolean, reset: boolean = false): (target: string, props?: {id?: string, data: any}) => void {
    let func = replace ? history.replace : (path: string, props: any) => history.push(path, { ...props, "ref": history.location.pathname })
    
    return useCallback((target: string = history.location.pathname, props?: Object) => {
        func(target, props)
        history.go(0)
    }, [func])
}
