import React from 'react'

const routes = {
    home: React.lazy(() => import('views/home')),
    report: React.lazy(() => import('views/report')),
    accounting: React.lazy(() => import('views/accounting')),
    settings: React.lazy(() => import('views/settings')),
    datahub: React.lazy(() => import('views/datahub')),
    datahubConnectSource: React.lazy(() => import('views/datahub-connect-source')),
    datahubSyncSource: React.lazy(() => import('views/datahub-sync-source')),
    certifications: React.lazy(() => import('views/certifications')),
    certificationsIssue: React.lazy(() => import('views/certifications-issue')),
    authenticate: React.lazy(() => import('views/auth'))
}

export default routes;